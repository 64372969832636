import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/react-hooks';
import { endOfDay, startOfDay, sub } from 'date-fns';
import { useSearchParams } from 'react-router-dom';

import * as queries from '@/graphql/queries';
import { addTooltip, MDASH } from '@/helpers';
import useParamsInstrumentId from '@/hooks/useParamsInstrumentId';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useOpenModal } from '@/hooks/useOpenModal';

import LoaderProgress from '@/components/common/LoaderProgress';
import NoDataFound from '@/components/common/NoDataFound';
import StatusBadge from '@/components/experiment/StatusesInfo/StatusBadge';
import { getInstrumentCurrentStatusBadgeData } from '@/components/experiment/StatusesInfo/helpers';

import { formatDateTime } from '@/pages/Home/helpers';
import { headerActions } from '@/store/slices/header';
import SensorByTelemetryModal from './components/SensorByTelemetryModal';

import styles from './InstrumentDashboard.module.scss';
import DateRange from './components/DateRange';

const cn = classnames.bind(styles);

const currentDate = endOfDay(new Date());
const defaultStartDate = startOfDay(sub(currentDate, { months: 3 }));

const InstrumentDashboard: FC = () => {
  const appDispatch = useAppDispatch();

  const instrumentId = useParamsInstrumentId();
  const [searchParams, setSearchParams] = useSearchParams();
  const instrumentName = searchParams.get('name') ?? null;

  const { isOpen: isSensorModalOpen, setIsOpen, onClose: closeSensorModal } = useOpenModal();
  const [sensorName, setSensorName] = useState<Nullable<string>>(null);

  const preselectedStartDate = searchParams.get('startDate') ?? null;
  const preselectedEndDate = searchParams.get('endDate') ?? null;

  const [startDate, setStartDate] = useState<Nullable<Date>>(
    preselectedStartDate ? new Date(preselectedStartDate) : defaultStartDate
  );
  const [endDate, setEndDate] = useState<Nullable<Date>>(
    preselectedEndDate ? new Date(preselectedEndDate) : currentDate
  );

  const setSearchParamItem = (searchParamName: string, searchParamValue: string) => {
    if (searchParamValue) {
      searchParams.set(searchParamName, searchParamValue);
    } else {
      searchParams.delete(searchParamName);
    }
    setSearchParams(searchParams);
  };

  const onChangeStartDate = useCallback((date: Nullable<Date>) => {
    if (!date) return;
    setSearchParamItem('startDate', date.toISOString());
    setStartDate(date);
  }, []);

  const onChangeEndDate = useCallback((date: Nullable<Date>) => {
    if (!date) return;
    setSearchParamItem('endDate', date.toISOString());
    setEndDate(date);
  }, []);

  const openTelemetryModal = (value: string) => {
    setSensorName(value);
    setIsOpen(true);
  };

  const closeTelemetryModal = () => {
    setSensorName(null);
    closeSensorModal();
  };

  const { data: instrumentData } = useQuery(queries.instrumentById, {
    variables: {
      instrumentId,
    },
  });

  const {
    data,
    loading: isInstrumentTelemetryLoading,
    error: isInstrumentTelemetryError,
  } = useQuery(queries.telemetryByInstrument, {
    variables: {
      instrumentId,
    },
  });

  const isEmptySensorsData = useMemo(
    () => !isInstrumentTelemetryLoading && !isInstrumentTelemetryError && !data?.telemetryByInstrument?.length,
    [isInstrumentTelemetryLoading, isInstrumentTelemetryError, data?.telemetryByInstrument]
  );

  const badgeData = useMemo(
    () =>
      instrumentData?.instrumentById?.currentStatus
        ? getInstrumentCurrentStatusBadgeData(instrumentData?.instrumentById.currentStatus)
        : null,
    [instrumentData?.instrumentById?.currentStatus]
  );

  useEffect(() => {
    appDispatch(headerActions.setNewLink({ title: 'instruments', link: '/instruments' }));
  }, []);

  useEffect(
    () => () => {
      searchParams.delete('startDate');
      searchParams.delete('endDate');
    },
    []
  );

  return (
    <div className={cn('dashboard')}>
      <div className={cn('dashboard__content')}>
        <div className={cn('instrument', 'content-block')}>
          <h2 className={cn('instrument__name')}>{instrumentName ?? instrumentData?.instrumentById?.name}</h2>
          <div className={cn('instrument__details')}>
            <div className={cn('instrument__details-block')}>
              <div className={cn('instrument__row')}>
                <span>Operator</span>
                <span>{instrumentData?.instrumentById?.currentStatus?.operator ?? MDASH}</span>
              </div>
              <div className={cn('instrument__row')}>
                <span>SW Version</span>
                <span>{instrumentData?.instrumentById?.currentStatus?.version ?? MDASH}</span>
              </div>
            </div>
            <div className={cn('instrument__details-block')}>
              <div className={cn('instrument__row')}>
                <span>Status</span>
                {badgeData ? <StatusBadge className={cn('instrument__status')} {...badgeData} /> : <span>{MDASH}</span>}
              </div>
              <div className={cn('instrument__row')}>
                <span>Run start</span>
                <span>
                  {formatDateTime(instrumentData?.instrumentById?.currentStatus?.timestamp, 'hh:mm:ss, MMM d yyyy') ??
                    MDASH}
                </span>
              </div>
            </div>
            {instrumentData?.instrumentById?.currentStatus?.experiment?.name && (
              <div className={cn('instrument__details-block')}>
                <div className={cn('instrument__row')}>
                  <span>Run name</span>
                  <span>{instrumentData?.instrumentById?.currentStatus?.experiment?.name}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={cn('sensors', 'content-block')}>
          <div className={cn('sensors__header')}>
            <h2 className={cn('sensors__name')}>Sensor reading</h2>
            {!isInstrumentTelemetryLoading && !isInstrumentTelemetryError && !isEmptySensorsData && (
              <DateRange
                startDate={startDate}
                onChangeStartDate={onChangeStartDate}
                endDate={endDate}
                onChangeEndDate={onChangeEndDate}
              />
            )}
          </div>
          {isInstrumentTelemetryLoading ? (
            <div className={cn('loader')}>
              <LoaderProgress isLoading={isInstrumentTelemetryLoading} theme="light" />
            </div>
          ) : (
            <div className={cn('sensors__list-container')}>
              {isEmptySensorsData || isInstrumentTelemetryError ? (
                <NoDataFound
                  alignment="center"
                  size="big"
                  textData={isInstrumentTelemetryError ? 'Something went wrong' : 'No data found'}
                  className={cn('no-data')}
                />
              ) : (
                <div className={cn('sensors__list')}>
                  {data?.telemetryByInstrument?.map((telemetry: TTelemetryByInstrument) => (
                    <button
                      onClick={() => openTelemetryModal(telemetry.name)}
                      key={telemetry.name}
                      className={cn('sensors__list-item')}
                    >
                      <span
                        className={cn('sensors__telemetry-name')}
                        {...addTooltip(telemetry.description, 'top-start')}
                      >
                        {telemetry.label}
                      </span>
                      <span>{`${telemetry.value} ${telemetry.postfix}`}</span>
                      <span className={cn('sensors__telemetry-time')}>
                        {formatDateTime(telemetry.time, 'hh:mm:ss, MMM d yyyy')}
                      </span>
                    </button>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {sensorName && (
        <SensorByTelemetryModal
          isOpen={isSensorModalOpen}
          onClose={closeTelemetryModal}
          instrumentId={instrumentId}
          name={sensorName}
          instrumentName={instrumentData?.instrumentById?.name}
        />
      )}
    </div>
  );
};

export default memo(InstrumentDashboard);
