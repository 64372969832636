// eslint-disable-next-line import/no-unresolved
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query';

export type TFetchExperimentsQuery = {
  nextToken?: string;
  time?: string;
  operator?: string;
  instrument?: string;
  projectId?: string;
  containScans?: boolean;
  limit?: number;
};

export type TFetchExperimentResult = {
  nextToken?: string;
  list: TExperiment[];
};

export type TFetchProjectsQuery = {
  collaborators?: string[];
  owner?: string;
  time?: string;
};

export type TFetchStatusResult = { status: string; version: string };

export type TFetchApiInfo = {
  openApiVersion: string;
  appVersion: string;
  releaseDate: string;
  documentationURL: string;
  changelogURL: string;
};

export type TAppEndpointBuilder = EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, NonNullable<unknown>, FetchBaseQueryMeta>,
  string,
  'appAPI'
>;

export type TIntEndpointBuilder = EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, NonNullable<unknown>, FetchBaseQueryMeta>,
  string,
  'intAPI'
>;

export type TAnnotationsEndpointBuilder = EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, NonNullable<unknown>, FetchBaseQueryMeta>,
  string,
  'antnAPI'
>;

export type TGenome = {
  friendlyName: string;
  name: string;
  active: boolean;
};

export enum ESequencingDataRequestDataType {
  guideseq = 'guideseq',
  fastq = 'fastq',
}

export type TFetchInstrumentsQuery = {
  operator?: string;
  instrument?: string;
};

export type TFetchGlobalEntityStatsQuery = {
  experimentId: string;
  laneList: TLane[];
  type: 'object' | 'cage' | 'all';
  globalCageIdMatched: number;
};

export type TFetchGlobalEntityStatsResponse = {
  scanId: string;
  laneId: string;
  objectsData: Nullable<Record<string, string>[]>;
  cagesData: Nullable<Record<string, string>[]>;
};

export type TGlobalEntityStats = {
  scanId: string;
  laneId: string;
  entityList: TEntity[];
  objectList: TEntity[];
};
