import { FC } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import classnames from 'classnames/bind';

import TextInput from '@/components/common/TextInput';
import '@/styles/datepicker.scss';

import styles from './DatePickerCustomInput.module.scss';

const cn = classnames.bind(styles);

const DatePickerCustomInput: FC<{
  onChange?: (value?: string) => void;
  value?: string;
}> = ({ value, onChange }) => (
  <TextInput type="time" value={value} onChange={(e) => onChange?.(e?.target?.value)} inputClassName={cn('input')} />
);

export default DatePickerCustomInput;
