import { MouseEvent, FC, useState, useEffect, memo } from 'react';
import { Popover as TinyPopover } from 'react-tiny-popover';
import classnames from 'classnames/bind';

import { TPopoverProps } from './types';

import PopoverContent from './PopoverContent';

import styles from './Popover.module.scss';

const cn = classnames.bind(styles);

export type { TPopoverOption } from './types';

const Popover: FC<TPopoverProps> = ({
  children = null,
  isOpen,
  setIsOpen = () => null,
  onClick = () => null,
  parentElement,
  styles: importedStyles,
  theme = 'light',
  contentId = '',
  align = 'end',
  positions = ['bottom', 'top', 'left', 'right'],
  padding = 10,
  useBackdrop = true,
  disableClosing = false,
  reposition,
  ...props
}) => {
  const [isClosing, setIsClosing] = useState(false);
  let closingTimer: NodeJS.Timeout;

  const handlePopoverClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (onClick) {
      onClick(e);
    }
  };

  const handlePopoverClose = () => {
    if (disableClosing) {
      return;
    }
    setIsClosing(true);

    closingTimer = setTimeout(() => {
      setIsOpen(false);
      setIsClosing(false);
    }, 300);
  };

  useEffect(
    () => () => {
      clearTimeout(closingTimer);
    },
    []
  );

  return (
    <TinyPopover
      isOpen={isOpen}
      onClickOutside={useBackdrop ? () => null : handlePopoverClose}
      clickOutsideCapture
      content={
        <PopoverContent
          closePopover={handlePopoverClose}
          isClosing={isClosing}
          theme={theme}
          {...props}
          contentId={contentId}
          useBackdrop={useBackdrop}
        />
      }
      positions={positions}
      align={align}
      padding={padding}
      containerStyle={importedStyles}
      reposition={reposition}
      parentElement={parentElement}
    >
      <div role="presentation" onClick={handlePopoverClick} className={cn('popover__wrapper')}>
        {children}
      </div>
    </TinyPopover>
  );
};

export default memo(Popover);
