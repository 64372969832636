import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { toast } from 'react-toastify';
import classnames from 'classnames/bind';

import { formatDate, formatExperimentDuration } from '@/helpers/common';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import useParamsProjectId from '@/hooks/useParamsProjectId';
import { useUserRole } from '@/hooks';
import { EExperimentTabId, useLinks } from '@/hooks/useLinks';

import { appAPI } from '@/store/services/app';
import { headerActions } from '@/store/slices/header';
import { datasetsActions, datasetsSelectors } from '@/store/slices/datasets';
import { preprocessingActions } from '@/store/slices/preprocessing';
import { experimentSelectors } from '@/store/slices/experiment';
import { gatesActions } from '@/store/slices/gates';
import { scatterplotsActions } from '@/store/slices/scatterplots';

import { TExperimentDataProps, withExperimentData } from '@/hoc/withExperimentData';
import { withExperimentToken } from '@/hoc/withExperimentToken';

import { ExperimentModalsProvider } from '@/contexts/ExperimentModalsContext';

import ControlPanel from '@/components/Layout/ControlPanel';
import Nav from '@/components/Layout/Header/Nav';
import Button from '@/components/common/Button';
import ExperimentPageHeader from '@/components/common/ExperimentPageHeader';
import icons from '@/components/common/icons';
import SearchInput from '@/components/common/SearchInput';
import OpenNavigatorButton from '@/components/navigator/OpenNavigatorButton';
import DownloadStatistics from '@/components/experiment/DownloadStatistics';
import { chartSettingsActions } from '@/store/slices/chartSettings';
import { chartDataActions } from '@/store/slices/chartData';

import { useDownloadExperiment } from './hooks/useDownloadExperiment';

import ActivityTab from './components/ActivityTab';
import JobsTab from './components/JobsTab';
import Data from './components/Data';
import DescriptionModal from './components/DescriptionModal';
import DownloadsTab from './components/DownloadsTab';
import PortalPreprocessing from './PortalPreprocessing';
import Settings from './components/Settings';
import Summary from './components/Sumary';

import styles from './Experiment.module.scss';

const cn = classnames.bind(styles);

const Experiment: FC<TExperimentDataProps> = ({ experimentId }) => {
  const appDispatch = useAppDispatch();

  const { isPermissionsRead, isJobRunReadAllowed } = useUserRole();
  const { generateExperimentLink } = useLinks();

  const currentAppScan = useSelector(experimentSelectors.selectCurrentScan);

  const experimentData = useSelector(experimentSelectors.selectCurrentExperiment);

  const { startExperimentDownload, isExperimentZippingInProgress } = useDownloadExperiment(
    experimentId,
    experimentData
  );

  const [searchParam, setSearchParam] = useSearchParams();
  const navigate = useNavigate();
  const projectId = useParamsProjectId();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const preprocessingDatasets = useSelector(datasetsSelectors.selectPreprocessingDatasets(experimentId));
  const isPreprocessingView = useSelector(datasetsSelectors.selectIsPreprocessingView);

  const totalCages = useMemo(
    () => experimentData?.numberOfCages ?? currentAppScan?.summary.numberOfCages,
    [currentAppScan?.summary, experimentData?.numberOfCages]
  );

  const [createExperimentInvitationLink, { isLoading: isInvitationLinkLoading }] =
    appAPI.useCreateExperimentInvitationLinkMutation();

  const formattedExperimentData: Partial<TExperiment> = {
    ...experimentData,
    dateCreated: formatDate(experimentData?.dateCreated, 'MMMM d, yyyy'),
    duration: formatExperimentDuration(experimentData?.duration),
  };

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const tabPanels = useMemo(() => {
    const tabList = [
      {
        id: EExperimentTabId.summary,
        title: 'Summary',
        content: <Summary />,
      },
      {
        id: EExperimentTabId.settings,
        title: 'Settings',
        content: <Settings />,
      },
      {
        id: EExperimentTabId.data,
        title: 'Data',
        content: <Data />,
      },
      {
        id: EExperimentTabId.downloads,
        title: 'Downloads',
        content: <DownloadsTab searchQuery={searchQuery} setIsLoading={setIsLoading} />,
      },
    ];
    if (!isPermissionsRead || isJobRunReadAllowed) {
      tabList.push({
        id: EExperimentTabId.jobs,
        title: 'Jobs',
        content: <JobsTab />,
      });
    }
    tabList.push({
      id: EExperimentTabId.activity,
      title: 'Activity',
      content: <ActivityTab searchQuery={searchQuery} setIsLoading={setIsLoading} />,
    });
    return tabList;
  }, [isPermissionsRead, isJobRunReadAllowed, searchQuery, setIsLoading]);

  const handleTab = (index: number) => {
    if (!index && searchParam.has('tab')) {
      searchParam.delete('tab');
    } else {
      searchParam.set('tab', tabPanels[index].id);
    }

    setSearchParam(searchParam);
  };

  const getSelectedIndex = () => {
    const tabId = searchParam.get('tab') ?? '';
    return tabPanels.findIndex((tab) => tab.id === tabId);
  };

  const getSelectedTab = () => {
    const tabIndex = getSelectedIndex();
    return tabIndex === -1 ? 0 : tabIndex;
  };

  const selectedTabId = useMemo(() => {
    const tabIndex = getSelectedTab();
    return tabPanels[tabIndex]?.id;
  }, [tabPanels, searchParam]);

  const isSearchAllowed = useMemo(
    () => [EExperimentTabId.downloads, EExperimentTabId.activity].includes(selectedTabId),
    [selectedTabId]
  );

  const handleSearchInputChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(value);
  };

  const handleResetSearchClick = () => {
    setSearchQuery('');
  };

  const generateLink = () => {
    if (experimentId) {
      createExperimentInvitationLink({ experimentId })
        .unwrap()
        .then(({ token }: { token: string }) => {
          const invitationLink = new URL(window.location.href);
          invitationLink.searchParams.set('token', token);

          navigator.clipboard.writeText(invitationLink.href).then(
            () => {
              toast.success('The invitation link is saved to clipboard');
            },
            () => {
              showErrorToast('Failed to save the invitation link');
            }
          );
        })
        .catch((error) => {
          showErrorToast(getErrorMessage(error));
        });
    }
  };

  const onPreprocessingClick = () => {
    appDispatch(preprocessingActions.resetState());
    appDispatch(datasetsActions.toggleIsPreprocessingView(true));
  };

  useEffect(() => {
    appDispatch(headerActions.removeAllLinks());

    if (projectId) {
      appDispatch(headerActions.setNewLink({ title: 'projects', link: `/projects` }));
      appDispatch(headerActions.setNewLink({ title: 'project', link: `/project/${projectId}` }));
    } else {
      appDispatch(headerActions.setNewLink({ title: 'dashboard', link: '/' }));
    }
  }, []);

  useEffect(() => {
    if (getSelectedIndex() === -1 && searchParam.has('tab')) {
      navigate(generateExperimentLink());
    }
  }, []);

  useEffect(() => {
    appDispatch(gatesActions.setSelectedGate(null));
    appDispatch(gatesActions.setOnlyGateToDisplay(null));
    appDispatch(scatterplotsActions.clearAllAxesGroups());
    appDispatch(chartSettingsActions.clearChartPresetSettingsСompletionStatusMap());
    appDispatch(chartSettingsActions.setIsObjectEntityEnabled(true));
    appDispatch(chartDataActions.setCurrentChartData(null));
  }, []);

  useEffect(() => {
    handleResetSearchClick();
  }, [selectedTabId]);

  const filteredPreprocessingDatasetsLength = useMemo(
    () => [...new Set(preprocessingDatasets.map((dataset) => `${dataset.scanId}-${dataset.laneId}`))].length,
    [preprocessingDatasets]
  );

  return (
    <ExperimentModalsProvider>
      <main className={cn('experiment')}>
        <DescriptionModal
          isOpen={isOpen}
          onRequestClose={closeModal}
          totalCages={totalCages}
          experiment={formattedExperimentData}
          animationType="slide-animation"
        />
        <div className={cn('experiment__container', 'container')}>
          <ExperimentPageHeader
            title={experimentData?.experimentName ?? ''}
            totalCages={totalCages}
            experimentData={experimentData}
            isEditableHeader
            experimentControls={
              <>
                <OpenNavigatorButton className={cn('controls__button', 'controls__button_with-text')} />

                <Button tooltip="Description" onClick={openModal} color="white" className={cn('controls__button')}>
                  <icons.AlignmentIcon />
                </Button>
                <Button
                  tooltip="Download experiment"
                  color="white"
                  className={cn('controls__button')}
                  onClick={startExperimentDownload}
                  isLoading={isExperimentZippingInProgress}
                >
                  <icons.DownloadIcon />
                </Button>

                <Button
                  tooltip="Share experiment"
                  onClick={generateLink}
                  color="white"
                  className={cn('controls__button')}
                  isLoading={isInvitationLinkLoading}
                >
                  <icons.ArrowRightIcon />
                </Button>

                <DownloadStatistics />
                {!!preprocessingDatasets.length && (
                  <Button
                    onClick={onPreprocessingClick}
                    color="dark"
                    className={cn('preprocessing-button')}
                    isFitContent
                  >
                    <icons.PreprocessingLargeIcon className={cn('preprocessing-button__icon')} />
                    <span className={cn('preprocessing-button__title')}>
                      Preprocessing required for{' '}
                      <span className={cn('preprocessing-button__assay-count')}>
                        {filteredPreprocessingDatasetsLength} ASSAY{filteredPreprocessingDatasetsLength > 1 && 'S'}
                      </span>
                    </span>
                  </Button>
                )}
              </>
            }
          />
          <div className={cn('content')}>
            <Tabs
              id="experiment-sidebar"
              data-test-id="experiment-sidebar"
              selectedTabClassName={cn('tabs__item_selected')}
              selectedIndex={getSelectedTab()}
              onSelect={(index) => handleTab(index)}
            >
              <ControlPanel isLoading={isLoading}>
                <ControlPanel.StickyReveal>
                  <Nav />
                </ControlPanel.StickyReveal>
                <ControlPanel.StickyContent>
                  <TabList data-test-id="tabs" className={cn('tabs')}>
                    {tabPanels.map((tab) => (
                      <Tab
                        key={`tab__${tab.id}`}
                        data-test-id={`tab__${tab.id}`}
                        className={cn('tabs__item')}
                        tabIndex="0"
                      >
                        {tab.title}
                      </Tab>
                    ))}
                  </TabList>
                </ControlPanel.StickyContent>
                {isSearchAllowed && (
                  <ControlPanel.RightActions>
                    <SearchInput
                      onChange={handleSearchInputChange}
                      onReset={handleResetSearchClick}
                      value={searchQuery}
                      className={cn('experiment__search')}
                    />
                  </ControlPanel.RightActions>
                )}
              </ControlPanel>
              {tabPanels.map((panel) => (
                <TabPanel key={panel.id} className={cn('content__tab-panel')}>
                  {panel.content}
                </TabPanel>
              ))}
            </Tabs>
          </div>
          <PortalPreprocessing isPreprocessingView={isPreprocessingView} />
        </div>
      </main>
    </ExperimentModalsProvider>
  );
};

export default withExperimentToken(withExperimentData(Experiment));
