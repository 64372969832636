import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { gateListSchema } from '@/validationSchemas';

import { GATE_API_VALIDATION_ERROR, generateJwtToken } from '@/helpers';
import { showErrorToast } from '@/helpers/errors';
import { prepareNewGateList } from '@/helpers/gates';

import { TAppEndpointBuilder } from '@/store/services/app/types';

import { transformGateResponse, transformSequencingDataResponse } from './dataProvider';

import {
  projectEndpoints,
  experimentEndpoints,
  assayEndpoints,
  instrumentsEndpoints,
  annotationsEndpoints,
} from './endpoints';

const getLane = (draftScans: TScan[], scanId: string, laneId: string) => {
  const scanIndex = draftScans.findIndex((scanItem) => scanItem.id === scanId);
  const scan = draftScans[scanIndex];
  const laneIndex = scan.lanes.findIndex((laneItem) => laneItem.id === laneId);
  return scan.lanes[laneIndex];
};

const updateChannelProperties = (
  draftScans: TScan[],
  scanId: string,
  laneId: string,
  channelId: string,
  propertyName: 'assay' | 'reagents',
  propertyNewValue: unknown
) => {
  const lane = getLane(draftScans, scanId, laneId);
  const channelsProperties = lane.channelsProperties ?? {};
  const channelProperties = channelsProperties[channelId] ?? {};
  lane.channelsProperties = {
    ...channelsProperties,
    [channelId]: {
      ...channelProperties,
      [propertyName]:
        typeof propertyNewValue === 'function' ? propertyNewValue(channelProperties[propertyName]) : propertyNewValue,
    },
  };
};

const updateDatasetProperties = (
  draftScans: TScan[],
  scanId: string,
  laneId: string,
  propertyName: string,
  propertyNewValue: string
) => {
  const lane = getLane(draftScans, scanId, laneId);
  const dataset = lane.dataset ?? {};
  lane.dataset = {
    ...dataset,
    [propertyName]: propertyNewValue,
  };
};

const prepareEndpoints = (build: TAppEndpointBuilder) => ({
  ...assayEndpoints(build),
  ...experimentEndpoints(build),
  ...projectEndpoints(build),
  ...instrumentsEndpoints(build),
  ...annotationsEndpoints(build),

  updateExperimentGates: build.mutation<TGate[], TUpdateGateRequest>({
    query: ({ experimentId, gateId, body }) => ({
      url: `/experiments/${experimentId}/gates/${gateId}`,
      method: 'PATCH',
      body,
    }),
    onQueryStarted: ({ experimentId, updatedGateList }, { dispatch }) => {
      dispatch(appAPI.util.upsertQueryData('fetchExperimentGates', experimentId, updatedGateList));
    },
  }),
  updateSequencingDataByLanes: build.mutation<TUpdatedSequencingDataFromServer, unknown>({
    query: ({ experimentId, sequencingData, flowcellType }) => ({
      url: `/sequencingdata/by-lanes`,
      method: 'PUT',
      body: { experimentId, flowcellType, sequencingData },
    }),
    onQueryStarted: ({ experimentId, sequencingData }, { dispatch, queryFulfilled }) => {
      queryFulfilled
        .then(({ data: sequencingDataFromServer }: { data: TUpdatedSequencingDataFromServer }) => {
          if (!sequencingDataFromServer?.createdData) {
            return;
          }
          dispatch(
            appAPI.util.upsertQueryData(
              'fetchSequencingDataByLanes',
              { experimentId, laneId: sequencingData[0].laneId },
              sequencingDataFromServer && transformSequencingDataResponse(sequencingDataFromServer.createdData)
            )
          );
        })
        .catch((_error) => null);
    },
  }),
  deleteSequencingDataByLanes: build.mutation<TDeletedSequencingDataFromServer, unknown>({
    query: ({ experimentId, laneId }) => ({
      url: `/sequencingdata/by-lanes`,
      method: 'DELETE',
      params: { experimentId, laneId },
    }),
    onQueryStarted: ({ experimentId, laneId }, { dispatch, queryFulfilled }) => {
      queryFulfilled
        .then(({ data: sequencingDataFromServer }: { data: TDeletedSequencingDataFromServer }) => {
          if (!sequencingDataFromServer?.deletedData) {
            return;
          }
          dispatch(
            appAPI.util.upsertQueryData(
              'fetchSequencingDataByLanes',
              { experimentId, laneId },
              sequencingDataFromServer && transformSequencingDataResponse(sequencingDataFromServer.deletedData)
            )
          );
        })
        .catch((_error) => null);
    },
  }),
  createExperimentGates: build.mutation<TGateFromServer, TCreateGateRequest>({
    query: ({ experimentId, body }) => ({
      url: `/experiments/${experimentId}/gates`,
      method: 'POST',
      body,
    }),
    onQueryStarted: ({ experimentId, newGate }, { dispatch, queryFulfilled }) => {
      queryFulfilled
        .then(({ data: gateFromServer }: { data: TGateFromServer }) => {
          const gateToParse = { ...newGate, id: gateFromServer.id, gateNodes: gateFromServer.gateNodes };
          const preparedGate = transformGateResponse(gateToParse, newGate.parentId, newGate.level);

          dispatch(
            appAPI.util.updateQueryData('fetchExperimentGates', experimentId, (draftGateList) => {
              if (!preparedGate) return draftGateList;

              const updatedGateList = prepareNewGateList(preparedGate, draftGateList);
              const { success: isGateListValid } = gateListSchema.safeParse(updatedGateList);

              if (!isGateListValid) {
                showErrorToast(GATE_API_VALIDATION_ERROR.invalidGateList);
                return draftGateList;
              }

              return updatedGateList;
            })
          );
        })
        .catch((_error) => null);
    },
  }),
  deleteExperimentGates: build.mutation<TGate[], TDeleteGateRequest>({
    query: ({ experimentId, gateId }) => ({
      url: `/experiments/${experimentId}/gates/${gateId}`,
      method: 'DELETE',
    }),
    onQueryStarted: ({ experimentId, updatedGateList }, { dispatch }) => {
      dispatch(appAPI.util.upsertQueryData('fetchExperimentGates', experimentId, updatedGateList));
    },
  }),
  deleteProject: build.mutation<TProject[], { projectId: string; updatedProjectList: TProject[] }>({
    query: ({ projectId }) => ({
      url: `/projects/${projectId}`,
      method: 'DELETE',
    }),
    onQueryStarted: ({ updatedProjectList }, { dispatch }) => {
      dispatch(appAPI.util.upsertQueryData('fetchProjectList', {}, updatedProjectList));
    },
    invalidatesTags: ['Project'],
  }),

  deleteExperimentGateChildrens: build.mutation<TGate[], TDeleteGateRequest>({
    query: ({ experimentId, gateId }) => ({
      url: `/experiments/${experimentId}/gates/${gateId}/nodes`,
      method: 'DELETE',
    }),
    onQueryStarted: ({ experimentId, updatedGateList }, { dispatch }) => {
      dispatch(appAPI.util.upsertQueryData('fetchExperimentGates', experimentId, updatedGateList));
    },
  }),

  deleteAssay: build.mutation({
    query: ({ assayId, experimentId, scanId, laneId, channelId }) => ({
      url: `/experiments/${experimentId}/scans/${scanId}/lanes/${laneId}/channels/${channelId}/assay/${assayId}`,
      method: 'DELETE',
    }),
  }),

  updateAssay: build.mutation({
    query: ({ experimentId, scanId, laneId, channelId, assay, isNew }) => {
      if (isNew) {
        return {
          url: `/experiments/${experimentId}/scans/${scanId}/lanes/${laneId}/channels/${channelId}/assay`,
          method: 'POST',
          body: { assayId: assay.id },
        };
      }
      return {
        url: `/experiments/${experimentId}/scans/${scanId}/lanes/${laneId}/channels/${channelId}/assay/${assay.id}`,
        method: 'PUT',
      };
    },
    onQueryStarted: ({ experimentId, scanId, laneId, channelId, assay }, { dispatch }) => {
      dispatch(
        appAPI.util.updateQueryData('fetchExperimentScans', experimentId, (draftScans) => {
          updateChannelProperties(draftScans as TScan[], scanId, laneId, channelId, 'assay', assay);
        })
      );
    },
  }),

  updateExperimentSampleFriendlyName: build.mutation<
    TGate[],
    { experimentId: string; scanId: string; laneId: string; friendlyName: string }
  >({
    query: ({ experimentId, scanId, laneId, friendlyName }) => ({
      url: `/experiments/${experimentId}/scans/${scanId}/lanes/${laneId}/sample/friendly-name`,
      method: 'PUT',
      body: { friendlyName },
    }),
    onQueryStarted: ({ experimentId, scanId, laneId, friendlyName }, { dispatch }) => {
      dispatch(
        appAPI.util.updateQueryData('fetchExperimentScans', experimentId, (draftScans) => {
          const lane = getLane(draftScans as TScan[], scanId, laneId) ?? {};
          if (lane) {
            lane.sampleFriendlyName = friendlyName;
          }
        })
      );
    },
  }),

  updateExperimentDatasetFriendlyName: build.mutation<
    TGate[],
    { experimentId: string; scanId: string; laneId: string; friendlyName: string }
  >({
    query: ({ experimentId, scanId, laneId, friendlyName }) => ({
      url: `/experiments/${experimentId}/scans/${scanId}/lanes/${laneId}/dataset/friendly-name`,
      method: 'PUT',
      body: { friendlyName },
    }),
    onQueryStarted: ({ experimentId, scanId, laneId, friendlyName }, { dispatch }) => {
      dispatch(
        appAPI.util.updateQueryData('fetchExperimentScans', experimentId, (draftScans) => {
          updateDatasetProperties(draftScans as TScan[], scanId, laneId, 'friendlyName', friendlyName);
        })
      );
    },
  }),

  deleteReagent: build.mutation({
    query: ({ experimentId, scanId, laneId, channelId, idReagentToDelete }) => ({
      url: `/experiments/${experimentId}/scans/${scanId}/lanes/${laneId}/channels/${channelId}/reagents/${idReagentToDelete}`,
      method: 'DELETE',
    }),
    onQueryStarted: ({ experimentId, scanId, laneId, channelId, reagentId }, { dispatch }) => {
      dispatch(
        appAPI.util.updateQueryData('fetchExperimentScans', experimentId, (draftScans) => {
          updateChannelProperties(draftScans as TScan[], scanId, laneId, channelId, 'reagents', (prev: unknown) =>
            (prev as TReagent[]).filter((reagent) => reagent.id !== reagentId)
          );
        })
      );
    },
  }),

  updateReagentList: build.mutation({
    query: ({ experimentId, scanId, laneId, channelId, reagentList }) => ({
      url: `/experiments/${experimentId}/scans/${scanId}/lanes/${laneId}/channels/${channelId}/reagents`,
      method: 'POST',
      body: reagentList.map((reagent: TReagent) => reagent.id),
    }),
    onQueryStarted: ({ experimentId, scanId, laneId, channelId, reagentList }, { dispatch }) => {
      dispatch(
        appAPI.util.updateQueryData('fetchExperimentScans', experimentId, (draftScans) => {
          updateChannelProperties(draftScans as TScan[], scanId, laneId, channelId, 'reagents', reagentList);
        })
      );
    },
  }),

  updateExperimentName: build.mutation<TExperiment, { experimentId: string; name: string }>({
    query: ({ experimentId, name }) => ({
      url: `/experiments/${experimentId}`,
      method: 'PATCH',
      body: { name },
    }),
    onQueryStarted({ experimentId, name }, { dispatch }) {
      dispatch(
        appAPI.util.updateQueryData('fetchExperimentById', experimentId, (cachedExperiment) => {
          cachedExperiment.name = name;
          cachedExperiment.experimentName = name;
        })
      );
    },
    invalidatesTags: ['ExperimentList'],
  }),
});

export const appAPI = createApi({
  reducerPath: 'appAPI',
  keepUnusedDataFor: 120, // This is how long (in seconds, Defaults to 60) RTK Query will keep your data cached for after the last component unsubscribes
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/expsvc`,
    prepareHeaders: (headers) =>
      new Promise((resolve) => {
        generateJwtToken().then(
          (token) => {
            headers.set('authorization', `Bearer ${token}`);
            resolve(headers);
          },
          () => {
            resolve(headers);
          }
        );
      }),
  }),
  tagTypes: ['Project', 'Experiment', 'ExperimentList', 'Scan', 'Gates'],
  endpoints: prepareEndpoints,
});
