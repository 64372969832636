import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

import { downloadFileSchema, experimentSchema } from '@/validationSchemas';

import { removeTrailingSlash } from '@/helpers';
import { getEntityFromServerFieldsForAxes } from '@/helpers/dimensions';

import {
  TFetchGlobalEntityStatsQuery,
  TGlobalEntityStats,
  TFetchGlobalEntityStatsResponse,
} from '@/store/services/app/types';
import { transformCageRow, transformObjectRow } from '@/store/services/cdnData/dataProvider';

export const transformExperimentListResponse = (experimentFromServers: TExperimentFromServer[]): TExperiment[] =>
  experimentFromServers.filter((experimentFromServer) => {
    if (!('id' in experimentFromServer)) {
      console.warn('The next experiment does not have an ID and will not appear in the list', experimentFromServer);
    }
    return experimentFromServer.id;
  });

export const transformExperimentResponse = (experimentFromServer: TExperimentFromServer): TExperiment => {
  const experimentFromServerChecked = experimentSchema.safeParse(experimentFromServer);

  if (!experimentFromServerChecked.success) {
    console.error(experimentFromServerChecked.error);
  }

  return {
    ...experimentFromServer,
    cloudPath: removeTrailingSlash(experimentFromServer.cloudPath),
    cloudSequencingDataPath: removeTrailingSlash(experimentFromServer.cloudSequencingDataPath),
  };
};

export const transformExperimentDownloadsResponse = (
  downloadFileFromServerList: z.infer<typeof downloadFileSchema>[]
): TDownloadFile[] =>
  downloadFileFromServerList
    .filter((downloadFileFromServer) => {
      const downloadFileCheckResult = downloadFileSchema.safeParse(downloadFileFromServer);
      if (!downloadFileCheckResult.success) {
        console.error(downloadFileCheckResult?.error);
      }
      return downloadFileCheckResult.success;
    })
    .map((downloadFileFromServer) => ({ ...downloadFileFromServer, uuid: uuidv4() }));

export const transformGlobalEntityStatsResponse = (
  data: TFetchGlobalEntityStatsResponse[],
  _meta: unknown,
  { laneList, type }: TFetchGlobalEntityStatsQuery
): TGlobalEntityStats[] =>
  data.map(({ scanId, laneId, objectsData, cagesData }) => {
    let objectList: TEntity[] = [];
    let cageList: TEntity[] = [];
    if (objectsData?.length) {
      const isClassesFromPipelineV5 = Object.hasOwn(objectsData[0], 'is_bead');
      const lane = laneList.find((laneItem) => laneItem.dataset.scanId === scanId && laneItem.id === laneId);
      objectList = lane
        ? objectsData.map((objectFromServer: Record<string, string>) =>
            transformObjectRow(objectFromServer, isClassesFromPipelineV5, lane)
          )
        : [];
    }
    if (cagesData?.length) {
      const fieldsForDimensions = getEntityFromServerFieldsForAxes(cagesData[0]);
      const lane = laneList.find((laneItem) => laneItem.dataset.scanId === scanId && laneItem.id === laneId);
      cageList = lane
        ? cagesData.map((objectFromServer: Record<string, string>) =>
            transformCageRow(objectFromServer, fieldsForDimensions, lane)
          )
        : [];
    }
    return {
      scanId,
      laneId,
      entityList: type === 'object' ? objectList : cageList,
      objectList,
    };
  });
