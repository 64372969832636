import { useEffect } from 'react';

/**
 * @param {string} targetKey - Key code to track
 * @param {function} handler - A handler function called when a key is pressed.
 */

export function useKeyDown(targetKey: string, handler: (event: KeyboardEvent) => void) {
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key !== targetKey) return;
      handler(event);
    };

    window.addEventListener('keydown', keyDownHandler);

    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, [targetKey, handler]);
}
