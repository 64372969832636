import { useSelector } from 'react-redux';
import { FC, memo, useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import classnames from 'classnames/bind';
import 'react-loading-skeleton/dist/skeleton.css';

import { throttle, downloadByUrl } from '@/helpers';

import { EActiveEntityUuidLastEdit, scatterplotsActions } from '@/store/slices/scatterplots';

import { TRenderEntityImage } from '@/hooks/useWebgl';
import { useAppDispatch } from '@/hooks/useAppDispatch';

import icons from '@/components/common/icons';
import Button from '@/components/common/Button';

import { experimentSelectors } from '@/store/slices/experiment';

import useDrawObjectsOnImageCrop from './hooks/useDrawObjectsOnImageCrop';

import PreviewImage from './PreviewImage';

import styles from './ImageButton.module.scss';

const cn = classnames.bind(styles);

type TImageButtonProps = {
  cropEntity: TEntity;
  contourObjectList?: TEntity[];
  renderEntityImage: TRenderEntityImage;
  cropEntityList?: (TEntity | undefined)[];
  onClick?: (entity: TEntity) => void;
  currentRenderSettings?: TRenderOptions;
  className?: string;
  isActionsAllowed?: boolean;
  currentTime?: string;
  isHoverActionEnabled?: boolean;
  renderSettingsList?: TRenderOptions[];
  currentEntityUuid?: string;
  scanIndex?: number;
};

const ImageButton: FC<TImageButtonProps> = ({
  cropEntity,
  contourObjectList = [],
  onClick,
  currentRenderSettings,
  renderEntityImage,
  className,
  isActionsAllowed = false,
  currentTime,
  cropEntityList = [],
  isHoverActionEnabled = false,
  renderSettingsList = [],
  currentEntityUuid = '',
  scanIndex,
}) => {
  const appDispatch = useAppDispatch();

  const experimentName = useSelector(experimentSelectors.selectCurrentExperimentName);

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const canvasRef = useRef<Nullable<HTMLCanvasElement>>(null);

  const handleMouseEnter = throttle(() => {
    if (!isHoverActionEnabled) return;
    appDispatch(
      scatterplotsActions.setActiveEntityUuid({ uuid: cropEntity.uuid, lastEdit: EActiveEntityUuidLastEdit.images })
    );
  });

  const handleMouseLeave = throttle(() => {
    if (!isHoverActionEnabled) return;
    appDispatch(scatterplotsActions.setActiveEntityUuid({ uuid: '', lastEdit: EActiveEntityUuidLastEdit.images }));
  });

  function handleCanvasClick() {
    if (onClick) {
      onClick(cropEntity);
    }

    if (isActionsAllowed) {
      setIsPreviewOpen(true);
    }
  }

  const handleDownloadButtonClick = () => {
    if (!isPreviewOpen && canvasRef.current) {
      const url = canvasRef.current?.toDataURL('image/png');
      downloadByUrl(url, `${experimentName} ${cropEntity.cageCropFileName}`);
    }
  };

  const drawObjects = useDrawObjectsOnImageCrop(canvasRef);

  useEffect(() => {
    if (canvasRef.current && cropEntity && currentRenderSettings) {
      renderEntityImage(canvasRef.current, cropEntity, currentRenderSettings, () => {
        drawObjects(cropEntity, contourObjectList, currentEntityUuid === cropEntity.uuid);
      });
    }
  }, [canvasRef.current, cropEntity, currentRenderSettings, drawObjects]);

  return (
    <div
      role="presentation"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={cn(
        'image-button',
        {
          'image-button_active': currentEntityUuid === cropEntity.uuid,
          'image-button_with-actions': isHoverActionEnabled,
        },
        className
      )}
    >
      <Skeleton className={cn('image-button__skeleton')} containerClassName={cn('image-button__loading-container')} />
      <canvas
        ref={canvasRef}
        className={cn('canvas')}
        id={currentTime ? `canvas_${currentTime}` : undefined}
        onClick={handleCanvasClick}
      />
      {isActionsAllowed && (
        <div className={cn('image-button__icon-container')}>
          <Button
            color="light"
            onClick={handleDownloadButtonClick}
            className={cn('image-button__icon', 'image-button__icon_circle', 'image-button__icon_black')}
          >
            <icons.DownloadIcon />
          </Button>
          <div className={cn('image-button__icon', 'image-button__icon_circle', 'image-button__icon_yellow')}>
            <icons.PlusIcon width={16} />
          </div>
        </div>
      )}
      <PreviewImage
        isOpen={isPreviewOpen}
        setIsOpen={setIsPreviewOpen}
        cropEntityList={cropEntityList}
        renderSettingsList={renderSettingsList}
        renderEntityImage={renderEntityImage}
        currentEntityUuid={currentEntityUuid}
        contourObjectList={contourObjectList}
        scanIndex={scanIndex}
      />
    </div>
  );
};

export default memo(ImageButton);
