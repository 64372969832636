import { FC, useCallback, useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import { v4 as uuidv4 } from 'uuid';

import Popover, { TPopoverOption } from '@/components/common/Popover';

import { getCurrentStatusBadgeData, getPipelineCurrentStatusBadgeData } from './helpers';

import StatusBadge from './StatusBadge';

import styles from './StatusesInfo.module.scss';

const cn = classnames.bind(styles);

type TStatusesInfoProps = {
  experiment: TExperiment;
  className?: string;
};

const StatusesInfo: FC<TStatusesInfoProps> = ({ experiment, className }) => {
  const { currentStatus, pipelineCurrentStatus } = experiment;

  const [isPopoverOpened, setIsPopoverOpened] = useState(false);

  const badgeDataList = useMemo(() => {
    const list = [];
    if (currentStatus?.status) {
      list.push(getCurrentStatusBadgeData(currentStatus));
    }
    if (pipelineCurrentStatus?.status) {
      list.push(getPipelineCurrentStatusBadgeData(pipelineCurrentStatus));
    }
    return list;
  }, [currentStatus]);

  const firstBudgeData = useMemo(() => badgeDataList[0], [badgeDataList]);

  const optionList = useMemo<TPopoverOption[]>(
    () =>
      badgeDataList.map((badgeData) => ({
        id: uuidv4(),
        title: <StatusBadge {...badgeData} shouldAlignIcons />,
      })),
    [badgeDataList]
  );

  const handlePopoverClick = useCallback(() => {
    setIsPopoverOpened((prev) => !prev);
  }, []);

  return (
    (firstBudgeData || !!optionList.length) && (
      <div className={cn('statuses-data', className)}>
        {firstBudgeData && <StatusBadge {...firstBudgeData} />}
        {optionList.length > 0 && (
          <Popover
            onClick={handlePopoverClick}
            setIsOpen={setIsPopoverOpened}
            isOpen={isPopoverOpened}
            options={optionList}
            contentClassName={cn('statuses-data__popover')}
            optionClassName={cn('statuses-data__option')}
            useBackdrop={false}
          >
            <div className={cn('statuses-data__all')}>
              All statuses
              <div className={cn('statuses-data__count')}>{optionList.length}</div>
            </div>
          </Popover>
        )}
      </div>
    )
  );
};

export default StatusesInfo;
