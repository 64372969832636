import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Amplify } from 'aws-amplify';
import { ToastContainer } from 'react-toastify';
import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from '@apollo/client';
import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import { generateJwtToken, getAwsClientMetadata } from '@/helpers';

import { datadogRum, DefaultPrivacyLevel } from '@datadog/browser-rum';
import { datadogConfig } from './datadog-exports';

import awsconfig from './aws-exports';

import App from './App';
import { store } from './store';
import reportWebVitals from './reportWebVitals';
import './conditionalGlobalMethodsOverride';

Amplify.configure({
  ...awsconfig,
  Auth: {
    ...awsconfig.Auth,
    clientMetadata: getAwsClientMetadata(),
  },
});

if (process.env.REACT_APP_DATADOG_APPLICATION_ID) {
  datadogRum.init({
    ...datadogConfig,
    defaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT,
  });
}

const appSyncConfig = {
  url: process.env.REACT_APP_APOLLO_GRAPHOS_API_URL ?? '',
  region: awsconfig.aws_appsync_region,
  auth: {
    type: awsconfig.aws_appsync_authenticationType as AUTH_TYPE.AMAZON_COGNITO_USER_POOLS | AUTH_TYPE.OPENID_CONNECT,
    jwtToken: generateJwtToken,
  },
};

const client = new ApolloClient({
  link: ApolloLink.from([createAuthLink(appSyncConfig), createSubscriptionHandshakeLink(appSyncConfig)]),
  cache: new InMemoryCache(),
  credentials: 'include',
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
          <ToastContainer />
        </BrowserRouter>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
