import { FC, ReactNode } from 'react';

import classnames from 'classnames/bind';
import EditableTitle from '@/pages/ExperimentsDashboard/components/EditableTitle';
import { addWordBreakAfterUnderscores } from '@/helpers';

import styles from '../PageHeader.module.scss';

const cn = classnames.bind(styles);

type TPageHeaderSlotProps = {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  className?: string;
  children?: ReactNode;
  isStatistics?: boolean;
  statusBadge?: Nullable<ReactNode>;
  wrapperClassName?: string;
  isEditable?: boolean;
  title?: string;
  onSave?: (newTitle: string) => void;
};

const PageHeaderTitle: FC<TPageHeaderSlotProps> = ({
  as: Tag = 'h2',
  className,
  children,
  isStatistics,
  wrapperClassName,
  statusBadge = null,
  isEditable,
  title,
  onSave,
}) => (
  <div className={cn('page-header__title-wrapper', wrapperClassName)}>
    {isEditable ? (
      <EditableTitle
        title={title ?? ''}
        onSave={onSave}
        className={cn(
          'page-header__title',
          `page-header__title_${Tag}`,
          { 'page-header__title_statistics': isStatistics },
          className
        )}
      />
    ) : (
      <Tag
        className={cn(
          'page-header__title',
          `page-header__title_${Tag}`,
          { 'page-header__title_statistics': isStatistics },
          className
        )}
      >
        {children ?? addWordBreakAfterUnderscores(title ?? '')}
      </Tag>
    )}
    {statusBadge}
  </div>
);
export default PageHeaderTitle;
