import { MutableRefObject, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { CELL_CONTOUR_DRAW_SETTINGS, contoursOnCanvas, CURRENT_OBJECT_DRAW_SETTINGS } from '@/helpers/objectsOnCanvas';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import { viewerSelectors } from '@/store/slices/viewer';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';

import { getContourList } from './helpers';

export default function useDrawObjectsOnImageCrop(canvasRef: MutableRefObject<Nullable<HTMLCanvasElement>>) {
  const chartId = usePlotChartIdContext();
  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));

  const allowDrawObjectsOnImageCrop = useSelector(viewerSelectors.selectAllowDrawObjectsOnImageCrop);
  const displayCurrentObjOnImageCrop = useSelector(viewerSelectors.selectDisplayCurrentObjOnImageCrop);
  const displayAllObjectsOnImageCrop = useSelector(viewerSelectors.selectDisplayAllObjectsOnImageCrop);

  const outlineAllObjects = useCallback(
    (entity: TEntity, entityList: TEntity[]) => {
      if (!canvasRef.current) {
        return;
      }

      const ctx = canvasRef.current.getContext('2d');
      const outlineList = getContourList(entityList);
      contoursOnCanvas.draw(ctx, outlineList, { ...CELL_CONTOUR_DRAW_SETTINGS, lineWidth: 1 });
    },
    [canvasRef.current]
  );

  const outlineCurrentObject = useCallback(
    (entity: TEntity) => {
      if (!canvasRef.current) {
        return;
      }

      const ctx = canvasRef.current.getContext('2d');
      const outlineList = getContourList([entity]);
      contoursOnCanvas.draw(ctx, outlineList, { ...CURRENT_OBJECT_DRAW_SETTINGS, lineWidth: 2 });
    },
    [canvasRef.current]
  );

  const drawObjects = useCallback(
    (entity: TEntity, entityList: TEntity[], isSelectedCrop: boolean) => {
      if (!allowDrawObjectsOnImageCrop) {
        // objects in primary_analysis.csv don't have "obj_bbox_in_cage_" fields
        return;
      }
      if (displayAllObjectsOnImageCrop) {
        outlineAllObjects(entity, entityList);
      }
      if (isObjectEntityEnabled && displayCurrentObjOnImageCrop && isSelectedCrop) {
        outlineCurrentObject(entity);
      }
    },
    [
      allowDrawObjectsOnImageCrop,
      displayAllObjectsOnImageCrop,
      outlineAllObjects,
      displayCurrentObjOnImageCrop,
      outlineCurrentObject,
    ]
  );

  return drawObjects;
}
